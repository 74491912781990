module.exports = [{
      plugin: require('/Users/spen/projects/spen-new/gatsby-new/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/spen/projects/spen-new/gatsby-new/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/spen/projects/spen-new/gatsby-new/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
